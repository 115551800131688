/* Under this width, we start removing bits of the UI*/
* {
  -webkit-font-smoothing: antialiased;
  box-sizing: border-box; }

html {
  background-color: #fafafa; }

html,
body,
#root {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  color: #333;
  margin: 0 auto;
  padding: 0;
  width: 100%;
  max-width: 1000px;
  height: 100%;
  font-size: 14px; }

.App {
  display: flex;
  width: 100%;
  height: 100%;
  padding: 20px;
  flex-direction: column;
  align-items: stretch; }
  @media screen and (max-width: 500px) {
    .App {
      padding: 5px; } }

h1, h2, h3, h4, h5, h6 {
  margin: 0;
  font-weight: 500; }

a {
  text-decoration: none;
  cursor: pointer;
  color: #0cc7ab;
  outline: none; }

a:hover {
  text-decoration: underline; }

.muted {
  opacity: .3; }

input, textarea, select {
  font-family: inherit; }

pre {
  font-family: "SFMono-Regular", Consolas, "Liberation Mono", Menlo, Courier, monospace;
  padding: 10px 20px;
  font-size: 16px;
  color: rgba(51, 65, 73, 0.6);
  background-color: rgba(51, 65, 73, 0.05); }

.page {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  overflow-x: hidden; }

.page-header {
  flex: 0 0 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 0 20px 0px;
  height: 50px;
  border-bottom: 1px solid rgba(0, 23, 105, 0.05); }
  @media screen and (max-width: 500px) {
    .page-header {
      flex: 0 0 30px;
      height: 30px;
      margin: 0 5px 0px; } }
  @media screen and (max-height: 500px) {
    .page-header {
      height: 40px;
      flex: 0 0 40px; } }
  .page-header .secret-list-refresh {
    margin-right: 10px; }
    .page-header .secret-list-refresh .icon {
      fill: #95a5a6; }

.page-content {
  position: relative;
  z-index: 0;
  flex-direction: column;
  flex: 1 1 100%;
  margin-top: 20px;
  padding: 0 0px 20px 20px;
  overflow: hidden; }
  @media screen and (max-width: 500px) {
    .page-content {
      padding: 0 5px;
      margin-top: 5px; } }

.page-content-actions {
  display: inherit;
  flex: 0 0 auto;
  background-color: #fafafa;
  margin-bottom: 20px; }
  .page-content-actions .secret-list-new {
    position: relative;
    z-index: 0;
    display: inherit;
    margin-right: 10px; }
    .page-content-actions .secret-list-new .button {
      margin-left: 10px; }
    .page-content-actions .secret-list-new .button:first-child {
      margin-left: 0px; }

.button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: none;
  background-color: transparent;
  padding: 0;
  outline: none;
  cursor: pointer;
  text-decoration: none; }
  .button[disabled] {
    opacity: .6;
    cursor: default; }
    .button[disabled]:hover, .button[disabled]:active {
      opacity: .6; }

.button--size-small {
  height: 28px;
  font-size: 11px;
  padding: 0 16px 1px;
  border-radius: 3px; }
  @media screen and (max-width: 500px) {
    .button--size-small {
      padding: 5px 16px; } }

.dropdown button,
.button--size-base {
  height: 44px;
  font-size: 12px;
  padding: 0 28px;
  border-radius: 3px; }

.button--style-default, .button--style-primary, .button--style-warning, .button--style-success {
  background-color: #e0e4e6;
  color: #333;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1px; }

.button--style-primary, .button--style-warning, .button--style-success {
  background-color: #0cc7ab;
  color: white; }

.button--style-warning {
  background-color: #e74c3c; }

.button--style-success {
  background-color: #27ae60; }

.button--style-default.button--size-base .icon, .button--size-base.button--style-primary .icon, .button--size-base.button--style-warning .icon, .button--size-base.button--style-success .icon,
.button--style-primary.button--size-base .icon,
.button--size-base.button--style-warning .icon,
.button--size-base.button--style-success .icon,
.button--style-warning.button--size-base .icon {
  margin-left: -12px;
  margin-right: 6px; }

.button--style-default.button--size-small .icon, .button--size-small.button--style-primary .icon, .button--size-small.button--style-warning .icon, .button--size-small.button--style-success .icon,
.button--style-primary.button--size-small .icon,
.button--size-small.button--style-warning .icon,
.button--size-small.button--style-success .icon,
.button--style-warning.button--size-small .icon {
  margin-left: -8px;
  margin-right: 4px; }

.dropdown button,
.button--style-icon {
  background: none;
  color: #333;
  padding: 0; }
  .dropdown button[disabled],
  .button--style-icon[disabled] {
    opacity: .2; }
    .dropdown button[disabled]:hover, .dropdown button[disabled]:active,
    .button--style-icon[disabled]:hover,
    .button--style-icon[disabled]:active {
      opacity: .2; }

.button:hover,
.button:active {
  opacity: .8;
  text-decoration: none; }

.dropdown {
  -webkit-user-select: none;
  position: relative;
  display: inline-block;
  vertical-align: middle; }
  .dropdown.open .dropdown-menu {
    visibility: visible;
    opacity: 1; }
  .dropdown.disabled .dropdown-toggle {
    cursor: not-allowed;
    opacity: .4; }

.dropdown-toggle {
  position: relative;
  z-index: 1;
  display: block;
  border: none;
  background: none;
  padding: 0;
  cursor: pointer;
  color: inherit;
  outline: none; }

.dropdown-toggle:active {
  outline: none; }

.dropdown-menu {
  -webkit-user-select: none;
  position: absolute;
  z-index: 2;
  visibility: hidden;
  opacity: 0;
  width: 250px;
  top: 100%;
  list-style: none;
  margin: 0;
  padding: 5px 0;
  background: white;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), 0 2px 6px 0 rgba(0, 0, 0, 0.1);
  border-radius: 2px; }

.dropdown-menu-left {
  left: 0; }

.dropdown-menu-right {
  right: 2px; }

.dropdown-menu .divider {
  margin: 5px 0;
  height: 1px;
  background-color: rgba(0, 0, 0, 0.1); }

.dropdown-menu-item {
  overflow: hidden; }

.dropdown-menu-item a {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 24px;
  color: inherit;
  text-decoration: none;
  cursor: pointer;
  padding: 0 16px;
  outline: none; }

.dropdown-menu-item a:hover,
.dropdown-menu-item a:active,
.dropdown-menu-item a:focus {
  background-color: #EEE;
  text-decoration: none; }

.dropdown-menu-item.disabled a {
  cursor: not-allowed;
  opacity: .4; }

.dropdown-menu-item.disabled a:hover,
.dropdown-menu-item.disabled a:active,
.dropdown-menu-item.disabled a:focus {
  background-color: inherit; }

.form {
  display: flex;
  flex-direction: column;
  align-items: stretch; }
  .form .input {
    margin: 0 0 20px; }
    .form .input:last-child {
      margin-bottom: 0; }
  .form h3 {
    margin: 0 0 20px;
    font-size: 12px;
    color: #e74c3c; }

/* Tooltip container */
.tooltip {
  position: relative;
  display: inline-block; }

/* Tooltip text */
.tooltip .tooltiptext {
  visibility: hidden;
  width: 100%;
  background-color: #555;
  color: #fff;
  text-align: center;
  padding: 5px 10px 5px 10px;
  border-radius: 6px;
  /* Position the tooltip text */
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 0%;
  /* Fade in tooltip */
  opacity: 0;
  transition: opacity 0.3s; }

/* Tooltip arrow */
.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 55%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent; }

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1; }

.tooltip .icon {
  margin-bottom: -2px; }

.icon {
  display: inline-block;
  fill: currentColor; }

@keyframes rotator {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(270deg); } }

@keyframes dash {
  0% {
    stroke-dashoffset: 187; }
  50% {
    stroke-dashoffset: 46.75;
    transform: rotate(135deg); }
  100% {
    stroke-dashoffset: 187;
    transform: rotate(450deg); } }

.spinner {
  display: block;
  width: 120px;
  height: 120px; }

.spinner-svg {
  animation: rotator 1.4s linear infinite; }

.spinner-svg-path {
  stroke: currentColor;
  stroke-dasharray: 187;
  stroke-dashoffset: 0;
  transform-origin: center;
  animation: dash 1.4s ease-in-out infinite; }

.input {
  position: relative;
  display: inline-flex;
  flex-direction: column; }
  .input label {
    display: block;
    font-weight: 500;
    margin: 0 0 10px;
    font-size: 11px;
    text-transform: uppercase;
    color: rgba(51, 65, 73, 0.6); }
  .input input,
  .input select {
    display: block;
    width: 100%;
    margin: 0;
    border: 1px solid rgba(51, 65, 73, 0.2);
    background: white;
    outline: none;
    -webkit-appearance: none; }
    .input input:focus,
    .input select:focus {
      border: 1px solid rgba(51, 65, 73, 0.4); }
    .input input:read-only,
    .input select:read-only {
      background: #fafafa; }
  .input select {
    cursor: pointer; }
    .input select[disabled] {
      cursor: default; }
  .input.input--size-extra-small input,
  .input.input--size-extra-small select {
    height: 18px;
    font-size: 10px;
    padding: 0 4px;
    border-radius: 1px; }
  .input.input--size-extra-small select {
    padding-right: 20px; }
    .input.input--size-extra-small select .icon {
      right: 4px; }
  .input.input--size-extra-small label {
    font-size: 8px; }
  .input.input--size-small input,
  .input.input--size-small select {
    height: 25px;
    font-size: 12px;
    padding: 0 8px;
    border-radius: 2px; }
  .input.input--size-small select {
    padding-right: 32px; }
    .input.input--size-small select .icon {
      right: 8px; }
  .input.input--size-base input,
  .input.input--size-base select {
    height: 44px;
    font-size: 13px;
    padding: 0 14px;
    border-radius: 2px; }
  .input.input--size-base select {
    padding-right: 36px; }
    .input.input--size-base select .icon {
      right: 14px; }
  .input.input--type-checkbox label {
    display: flex;
    flex-direction: row;
    align-items: center;
    user-select: none;
    cursor: pointer; }
  .input.input--type-checkbox input {
    display: none; }
  .input.input--type-select .input--type-select--input {
    position: relative; }
  .input.input--type-select .icon {
    position: absolute;
    width: 16px;
    height: 16px;
    top: 50%;
    margin-top: -7px;
    right: 8px;
    fill: rgba(51, 65, 73, 0.4);
    pointer-events: none; }
  .input.input--error input {
    border-color: #e74c3c; }
  .input.input--error .input-error {
    color: #e74c3c;
    font-size: 11px; }

.input-label-actions:before {
  display: inline-block;
  content: '-';
  text-align: center;
  width: 16px; }

.input-label-actions a {
  display: inline-block;
  outline: none;
  margin-right: 8px;
  user-select: none; }

.input--wrapper {
  position: relative; }

.input--wrapper .dropdown {
  display: block; }

.input--wrapper .dropdown-menu {
  width: 100%; }

.input--password-show {
  position: absolute;
  bottom: 0;
  right: 10px;
  opacity: 0.7; }

.checkbox {
  display: block;
  position: relative;
  margin: 0 6px 0 2px;
  width: 24px;
  height: 16px;
  border-radius: 16px; }
  .checkbox:after {
    content: '';
    position: absolute;
    top: 2px;
    left: 2px;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: white;
    transition: all .2s; }
  .checkbox.checkbox--checked {
    background-color: #0cc7ab; }
    .checkbox.checkbox--checked:after {
      margin-left: 8px; }
  .checkbox.checkbox--unchecked {
    background-color: #C7C9CD; }
    .checkbox.checkbox--unchecked:after {
      margin-left: 0; }

.modal,
.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0; }

.modal-backdrop {
  background-color: rgba(51, 65, 73, 0.8); }

.modal {
  z-index: 10;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5%;
  overflow: auto; }
  .modal.modal--centered {
    justify-content: center; }

.modal-dialog {
  position: relative;
  background-color: #FFF;
  max-width: 480px;
  width: 100%;
  border-radius: 3px; }

.modal-dialog:focus {
  outline: none; }

.modal-dialog-close {
  position: absolute;
  border: none;
  background: none;
  margin: 10px;
  padding: 10px;
  cursor: pointer;
  color: #95a5a6;
  outline: none; }
  .modal-dialog-close .icon {
    display: block; }
  .modal-dialog-close:hover, .modal-dialog-close:active {
    opacity: .8; }

.modal-body {
  padding: 40px; }
  .modal-body .flash {
    margin-bottom: 10px; }

.modal-header {
  padding: 40px; }

.modal-header + .modal-body {
  padding-top: 0; }

.modal-header-title {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 300; }
  .modal-header-title .icon {
    flex: 0 0 24px;
    margin-right: 8px;
    fill: #95a5a6; }
  .modal-header-title .text {
    flex: 0 1 auto;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; }
  .modal-header-title .icon--id-description {
    opacity: .4; }

.modal-footer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding: 40px; }
  .modal-footer .button {
    margin-left: 10px; }
    .modal-footer .button:first-child {
      margin-left: 0; }

.modal-header + .modal-footer,
.modal-body + .modal-footer {
  padding-top: 0;
  margin-top: -10px; }

.flash {
  display: flex;
  flex-direction: row;
  color: rgba(51, 51, 51, 0.8);
  background-color: rgba(51, 51, 51, 0.1);
  padding: 10px; }

.flash-icon {
  margin-right: 6px; }
  .flash-icon .icon {
    display: block; }

.flash-content {
  line-height: 18px; }

.tabs .nav-tabs {
  display: flex;
  align-items: center;
  list-style: none;
  margin: 0;
  padding: 0; }
  .tabs .nav-tabs li a {
    display: flex;
    align-items: center;
    height: 42px;
    border-bottom: 2px solid transparent;
    color: inherit;
    outline: none;
    text-decoration: none;
    margin-right: 25px; }
  .tabs .nav-tabs li.active a {
    border-bottom: 2px solid currentColor; }
  .tabs .nav-tabs li.active:last-child a {
    margin-right: 0; }
  .tabs .nav-tabs li.disabled {
    opacity: .2; }

.tabs .tab-pane {
  display: none; }
  .tabs .tab-pane.active {
    display: block; }

.tabs .tab-content {
  margin-top: 20px; }

.breadcrumb {
  flex: 1 1 auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 24px;
  font-size: 18px; }
  @media screen and (max-width: 500px) {
    .breadcrumb {
      font-size: 1rem; } }

.breadcrumb-item .icon {
  fill: #001769; }

.breadcrumb-item-separator {
  display: block; }

.breadcrumb-item-separator:last-of-type {
  display: none; }

.breadcrumb-link {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #304249;
  text-decoration: none; }
  .breadcrumb-link .icon {
    margin-right: 4px; }

.breadcrumb-link:hover {
  text-decoration: underline; }

.breadcrumb-link--active {
  color: inherit;
  cursor: default; }

.breadcrumb-link--active:hover {
  text-decoration: none; }

.layout {
  min-width: 300px;
  max-height: calc(100% - 52px);
  flex: 1 1 100%;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  margin-bottom: 10px; }

.layout-pane {
  display: contents;
  align-items: stretch; }

.secretin-version {
  display: block;
  text-align: right;
  font-size: 12px; }

.secretin-version-text {
  opacity: .3; }

.new-in-secretin-version {
  color: #e74c3c;
  text-align: right;
  font-size: 30px;
  cursor: pointer; }

.footer {
  flex: 1;
  flex-direction: column; }

.sidebar {
  position: relative;
  flex: 0 0 30vw;
  min-width: 90px;
  max-width: 240px; }
  .sidebar:after {
    content: '';
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    border-left: 1px solid rgba(0, 23, 105, 0.05); }

.sidebar-header {
  display: flex;
  align-items: center;
  margin: 0 20px;
  font-size: 18px;
  font-weight: 500;
  height: 50px;
  color: rgba(51, 65, 73, 0.4);
  border-bottom: 1px solid rgba(0, 23, 105, 0.05); }
  .sidebar-header a {
    color: inherit;
    text-decoration: inherit; }
  @media screen and (max-width: 500px) {
    .sidebar-header {
      font-size: 12px;
      margin: 0 5px;
      height: 30px; } }
  @media screen and (max-height: 500px) {
    .sidebar-header {
      height: 40px; } }

.sidebar-menu {
  list-style: none;
  margin: 20px;
  padding: 0; }
  @media screen and (max-width: 500px) {
    .sidebar-menu {
      margin: 5px; } }
  @media screen and (max-height: 500px) {
    .sidebar-menu {
      margin: 10px 20px 10px 20px; } }

.sidebar-menu-item {
  margin: 0;
  padding: 0; }

.sidebar-menu-item .icon {
  fill: #001769; }

.sidebar-menu-item .icon--id-logout {
  fill: #e74c3c; }

@media screen and (max-width: 500px) {
  .sidebar-menu-item--home {
    display: none; } }

.sidebar-menu-link {
  display: flex;
  flex-direction: row;
  align-items: center;
  min-height: 44px;
  font-size: 15px;
  color: inherit;
  padding: 0 12px 0 8px;
  margin: 0 0 2px;
  border-radius: 3px; }
  @media screen and (max-width: 500px) {
    .sidebar-menu-link {
      font-size: 12px;
      padding: 0 4px 0 2px; } }
  @media screen and (max-height: 500px) {
    .sidebar-menu-link {
      min-height: 20px; } }

.sidebar-menu-link:hover {
  background-color: rgba(51, 65, 73, 0.1);
  text-decoration: none; }

.sidebar-menu-link--active {
  background-color: rgba(51, 65, 73, 0.05); }

.sidebar-menu-link .icon {
  margin-right: 8px; }

.sidebar-separator {
  margin-top: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid rgba(0, 23, 105, 0.05); }
  @media screen and (max-height: 500px) {
    .sidebar-separator {
      margin-top: 7px;
      margin-bottom: 7px; } }

.layout-pane-content {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  align-items: stretch; }

.secret-list-content-table {
  position: relative;
  display: block;
  z-index: 0;
  height: calc(100% - 52px);
  flex: 1 1 100%;
  width: 100%;
  border-collapse: collapse;
  white-space: nowrap;
  table-layout: fixed;
  overflow-x: hidden; }

.secret-list-content-table-body {
  display: flex;
  flex-direction: column; }

.secret-list-content-table-header {
  display: flex;
  text-align: left;
  flex-direction: column;
  position: sticky;
  top: 0;
  z-index: 2;
  background-color: #fafafa; }

.secret-list-content-table-header div > div {
  font-size: 11px;
  font-weight: 500;
  padding: 0 0 4px 10px;
  border-bottom: 1px solid #ecf0f1;
  text-transform: uppercase;
  opacity: .3; }

.secret-list-content-table-header div > div:last-child {
  padding-right: 10px; }

.secret-list-content-table-column--shared-with,
.secret-list-content-table-column--actions {
  max-width: 120px; }

.secret-list-placeholder {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; }

.secret-list-placeholder-title {
  font-size: 24px;
  font-weight: 500;
  margin: 0 0 10px; }

.secret-list-placeholder-text {
  font-size: 18px;
  margin: 0 0 20px; }

.secret-list-placeholder-actions {
  display: flex;
  flex-direction: row;
  align-items: center; }
  .secret-list-placeholder-actions .button {
    margin-right: 10px; }
    .secret-list-placeholder-actions .button:last-child {
      margin-right: 0; }

.secret-in-folder {
  font-size: 12px;
  line-height: 18px;
  margin-top: -20px;
  display: inherit;
  margin-right: 10px; }

.folder {
  margin: 20px; }

.secret-list-item {
  min-height: 50px;
  background-color: transparent; }

.secret-list-item:hover {
  background-color: rgba(51, 65, 73, 0.02); }

.secret-list-item > div {
  padding: 0 0 0 10px;
  border-bottom: 1px solid rgba(0, 23, 105, 0.05);
  vertical-align: middle; }
  @media screen and (max-width: 500px) {
    .secret-list-item > div {
      padding: 0 0 0 2px; } }

.secret-list-content-table--is-dragging .secret-list-item {
  opacity: .1; }
  .secret-list-content-table--is-dragging .secret-list-item.secret-list-item--is-dragging {
    opacity: 1; }
  .secret-list-content-table--is-dragging .secret-list-item.secret-list-item--can-drop {
    opacity: 1; }
  .secret-list-content-table--is-dragging .secret-list-item.secret-list-item--is-over.secret-list-item--can-drop {
    background-color: rgba(51, 65, 73, 0.1); }
    .secret-list-content-table--is-dragging .secret-list-item.secret-list-item--is-over.secret-list-item--can-drop .secret-list-item-column--title {
      text-decoration: underline; }

.secret-list-item-column {
  line-height: 50px; }

.secret-list-item-column--title {
  display: inline-block;
  overflow: hidden;
  width: 40%; }
  .secret-list-item-column--title a {
    display: flex;
    flex-direction: row;
    align-items: center;
    color: inherit; }
  .secret-list-item-column--title .icon {
    flex: 0 0 24px;
    margin-right: 8px;
    fill: #95a5a6; }
    .secret-list-item-column--title .icon.icon--id-description {
      opacity: .4; }
    .secret-list-item-column--title .icon.icon--id-windows {
      opacity: .4; }
  .secret-list-item-column--title .icon--size-small {
    margin-right: 0px; }
  .secret-list-item-column--title .text {
    flex: 1 1 auto;
    overflow: hidden;
    text-overflow: ellipsis; }
  @media screen and (max-width: 500px) {
    .secret-list-item-column--title {
      width: 85%; } }

.secret-list-item-column--last-modified {
  overflow: hidden;
  display: inline-block;
  width: 25%; }
  @media screen and (max-width: 500px) {
    .secret-list-item-column--last-modified {
      display: none; } }

.secret-list-item-column--shared-with {
  overflow: hidden;
  display: inline-block;
  width: 20%; }
  @media screen and (max-width: 500px) {
    .secret-list-item-column--shared-with {
      display: none; } }

.secret-list-item-column--actions {
  display: inline-block;
  width: 15%; }
  @media screen and (max-width: 500px) {
    .secret-list-item-column--actions {
      width: 15%;
      height: 100%; } }

.secret-list-item-column--actions .dropdown-toggle .icon {
  display: block;
  fill: #95a5a6;
  margin: 0 4px; }

.secret-list-item-column--actions .dropdown-toggle {
  opacity: 0; }
  @media screen and (max-width: 500px) {
    .secret-list-item-column--actions .dropdown-toggle {
      opacity: 1; } }

.secret-list-item:hover .secret-list-item-column--actions .dropdown-toggle,
.secret-list-item-column--actions .dropdown.open .dropdown-toggle {
  opacity: 1; }

.secret-list-folder {
  font-size: 18px; }

.secret-list-folder-info {
  padding: 40px 0 10px 10px;
  border-bottom: 1px solid rgba(0, 23, 105, 0.05);
  vertical-align: middle; }

.secret-editable-title {
  font-size: inherit;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  border-color: transparent; }
  .secret-editable-title:hover, .secret-editable-title:active, .secret-editable-title:focus {
    border-color: inherit; }

.secret-fields {
  display: flex;
  flex-direction: column;
  align-items: stretch; }
  .secret-fields .input {
    margin: 0 0 0px; }
  .secret-fields .secret-field {
    margin: 0 0 20px; }
    .secret-fields .secret-field:last-child {
      margin-bottom: 0; }

.secret-field {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: flex-end; }
  .secret-field .input {
    flex: 1 1 auto; }
  .secret-field .input + .button {
    margin-left: 10px; }

.secret-field-action {
  position: absolute;
  left: 100%;
  margin-left: 10px; }

.secret-users-list {
  width: 100%; }

.secret-users-list-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px; }
  .secret-users-list-item:last-child {
    margin-bottom: 0; }

.secret-users-list-item-infos,
.secret-users-list-item-actions {
  display: flex;
  flex-direction: row;
  align-items: center; }

.secret-users-list-item-infos {
  flex: 1 1 auto; }
  .secret-users-list-item-infos .user-avatar {
    margin-right: 10px; }

.secret-users-list-item-actions .button {
  margin-left: 10px;
  padding: 0;
  background: none; }

.secret-users-list-new {
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  align-items: flex-start; }
  .secret-users-list-new .input {
    margin-right: 10px; }
  .secret-users-list-new .input--type-text {
    flex: 1 1 auto; }
  .secret-users-list-new .button {
    padding: 0;
    background: none; }

.secret-history {
  width: 100%; }

.secret-history-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  border: 1px solid;
  border-radius: 5px;
  margin-bottom: 25px;
  max-height: 40px; }

.secret-history-navigation {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  min-width: 60px; }

.user-avatars {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
  height: 50px; }

.user-avatars .user-avatar {
  transition: margin-left .2s; }

.user-avatars .user-avatar--size-base {
  margin-left: -12px; }

.user-avatars .user-avatar:first-child {
  margin-left: 0; }

.user-avatars .user-avatar:hover + .user-avatar {
  margin-left: 0; }

.user-avatar {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-weight: 500;
  text-transform: uppercase;
  border-radius: 50%;
  border: 2px solid white; }

.user-avatar--size-small {
  width: 18px;
  height: 18px;
  font-size: 8px;
  line-height: 8px; }

.user-avatar--size-base {
  width: 24px;
  height: 24px;
  font-size: 10px;
  line-height: 10px; }

.user-avatar--size-large {
  width: 32px;
  height: 32px;
  font-size: 12px;
  line-height: 12px; }

.user-avatar--color-turquoise {
  background-color: #0cc7ab; }

.user-avatar--color-emerland {
  background-color: #2ecc71; }

.user-avatar--color-peter-river {
  background-color: #3498db; }

.user-avatar--color-amethyst {
  background-color: #9b59b6; }

.user-avatar--color-wet-asphalt {
  background-color: #34495e; }

.user-avatar--color-green-sea {
  background-color: #16a085; }

.user-avatar--color-nephritis {
  background-color: #27ae60; }

.user-avatar--color-belize-hole {
  background-color: #2980b9; }

.user-avatar--color-ocean-my-secret-in {
  background-color: #001769; }

.user-avatar--color-sun-flower-my-secret-in {
  background-color: #edb824; }

.user-avatar--color-turquoise-my-secret-in {
  background-color: #0cc7ab; }

.user-avatar--color-wisteria {
  background-color: #8e44ad; }

.user-avatar--color-midnight-blue {
  background-color: #2c3e50; }

.user-avatar--color-sun-flower {
  background-color: #f1c40f; }

.user-avatar--color-carrot {
  background-color: #e67e22; }

.user-avatar--color-alizarin {
  background-color: #e74c3c; }

.user-avatar--color-clouds {
  background-color: #ecf0f1; }

.user-avatar--color-concrete {
  background-color: #95a5a6; }

.user-avatar--color-orange {
  background-color: #f39c12; }

.user-avatar--color-pumpkin {
  background-color: #d35400; }

.user-avatar--color-pomegranate {
  background-color: #c0392b; }

.user-avatar--color-silver {
  background-color: #e0e4e6; }

.user-avatar--color-asbestos {
  background-color: #7f8c8d; }

.user-connect {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; }

.user-connect-title {
  text-align: center;
  margin: 0 0 20px; }
  .user-connect-title small {
    margin-left: 8px;
    font-size: inherit;
    font-weight: 300; }
  @media screen and (max-height: 500px) {
    .user-connect-title {
      margin: 0 0 10px; } }

.user-connect-form {
  width: 280px; }

.user-connect-more {
  text-align: center;
  margin: 10px 0 0;
  color: #0cc7ab; }

.user-connect-create {
  text-align: center;
  margin: 10px 0 0; }
  .user-connect-create a {
    color: #0cc7ab; }

.user-connect-progress-bar {
  border-top: 3px solid #0cc7ab; }

.user-connect-progress {
  color: rgba(51, 65, 73, 0.8);
  margin-left: 20px;
  margin-right: 20px; }

.user-connect-progress-title {
  font-size: 16px;
  float: right; }

.user-connect-progress-text {
  font-size: 16px;
  line-height: 24px;
  float: left; }

.options {
  padding: 20px;
  overflow: auto; }

.options-section {
  margin: 0 0 40px; }

.options-section-title {
  margin: 0 0 20px; }

.options-section-item {
  margin: 0 0 10px; }

.options-section-subitem {
  margin: 0 0 20px;
  padding-left: 32px; }

.options-section-subitem-save {
  margin: 10px 0 20px; }

.options-changepassword {
  margin-left: 20px; }

.options-changepassword-infos {
  margin-top: 10px; }

.options-changepassword-success {
  color: #27ae60;
  text-align: center;
  font-size: 20px; }

.totp-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; }

.totp-form-qrcode {
  margin: 0 0 20px; }

.import-progress {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; }
  .import-progress .spinner,
  .import-progress .icon {
    margin: 0 0 20px; }
  .import-progress .spinner {
    color: #0cc7ab; }
  .import-progress .icon--id-done {
    color: #0cc7ab; }

.import-progress-bar {
  background-color: #e0e4e6;
  position: relative;
  width: 180px;
  margin: 0 6px 0 2px;
  height: 16px;
  border-radius: 16px; }

.import-progress-bar-status {
  content: '';
  position: absolute;
  top: 2px;
  left: 2px;
  min-width: 12px;
  height: 12px;
  border-radius: 6px;
  background-color: #334149;
  transition: all .2s; }

.import-drop-zone {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 200px; }
  .import-drop-zone .icon {
    color: rgba(51, 65, 73, 0.6); }
  .import-drop-zone:before {
    pointer-events: none;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border: 2px dashed rgba(51, 65, 73, 0.2);
    margin: 10px; }

.import-drop-zone-input {
  display: none; }

.import-drop-zone-label label {
  font-weight: 500;
  cursor: pointer; }
  .import-drop-zone-label label:hover {
    text-decoration: underline; }
